import React from "react"
import { graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'

import SEO from "../components/seo"

import { linkResolver } from '../utils/linkResolver'
import PageHeaderSlim from '../components/pageHeaderSlim/pageHeaderSlim'
   
export const query = graphql`
  query PastResolutionsQuery {
  prismic {
    allPrevious_resolutionss {
      edges {
        node {
          title
          page_content
          background_video {
            ... on PRISMIC__ImageLink {
              _linkType
              url
              width
              height
            }
            ... on PRISMIC__FileLink {
              _linkType
              url
            }
          }
          _meta {
            uid
          }
        }
      }
    }
  }
}
`

const PastResolutions = ({ data }) => {
  const doc = data.prismic.allPrevious_resolutionss.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Past Resolutions" />
      <PageHeaderSlim
        title={doc.node.title}
        background={doc.node.background_video}
      />
      <div className={`cols-wrapper section-slim`}>
        <div className="cols">
          <div className={`col-5-md col-5-lg`}>
            {!!doc.node.page_content &&
              <div className={`t-body text-body`}>
                <RichText
                  render={doc.node.page_content}
                  linkResolver={linkResolver}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PastResolutions
